<template>
  <div class="d-flex flex-column align-stretch">
    <v-btn
        v-if="authenticationConfiguration != null && authenticationConfiguration.loginButtonVisible == true &&
         (ssoAccounts === undefined || ssoAccounts.length === 0)
        "
        class="mt-4"
        type="submit"
        :loading="loadingSSO"
        :class="{'mt-4': ['xs', 'sm'].indexOf(breakpoints) === -1}"
        color="black"
        dark
        rounded
        @click.prevent="loginSSO"
    >
      <v-icon left>mdi-lock</v-icon>
      {{ $t("home.login.loginsso") }}
    </v-btn>
    <!-- si plusieur compte afficher les btn de connexion de chaque compte -->
    <v-row  v-if="ssoAccounts !== undefined && ssoAccounts.length > 0"
            align="center"
            justify="center"
            class="d-flex flex-column align-stretch"
    >
    <!-- ce connecter avec le compte: -->
      <v-col cols="12" class="d-flex flex-column align-stretch justify-center">
        <v-icon 
          left
          :size="{ xs: 50, sm: 60, md: 70, lg: 80 , xl: 85 }[breakpoints]"
          class="gradient-icon"      
        >mdi-account-circle</v-icon>
        <!-- couleur du text en primary -->
        <v-card-subtitle class="text-center purple--text text--darken-2">{{ $t("home.loginSSOconnectwith") }}</v-card-subtitle>
        <!-- <v-subheader>{{ $t("home.loginSSOconnectwith") }}</v-subheader> -->
        <v-btn v-for="(account, index) in ssoAccounts" :key="index"
          class="align-self-center mt-4 d-flex flex-column align-stretch"
          type="submit"
          :min-width="{ xs: 250, sm: 250, md: 250, lg: 300 , xl: 300 }[breakpoints]"
          :class="{'mt-4': ['xs', 'sm'].indexOf(breakpoints) === -1}"
          :loading="multipleLoadingSSO === index"
          color="primary"
          dark
          rounded
          @click.prevent="SSO_multiAccount(account, index)"
        >
          <v-icon 
          :size="{ xs: 15, sm: 17, md: 20, lg: 22 , xl: 25 }[breakpoints]"
          left>mdi-account-circle</v-icon>
          {{ account.firstname }} {{ account.lastname }}
          <v-spacer></v-spacer>
          <span title="Quality" >{{ account.quality }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoginService from "@/Services/LoginService";
import Vuex from "vuex"

export default {
  name: "LoginSSO",
  computed: {
    ...Vuex.mapState(["preferences","authenticationConfiguration", "accountsSSO"]),
    ...Vuex.mapMutations(["setAccountsSSO"]),
    breakpoints() {
      return this.$vuetify.breakpoint.name
    },
    ssoAccounts() {
      return this.accountsSSO !== undefined ? this.accountsSSO : [] 
    }
  },
  data() {
    return {
      loadingSSO: false,
      accounts: [],
      multipleLoadingSSO: false
    }
  },
  methods: {
    loginSSO: async function() {
      this.loadingSSO = true
      await LoginService.loginSSO()
      await this.sleep(1500)
      this.loadingSSO = false
    }, 
    SSO_multiAccount: async function(account, index) {
      this.multipleLoadingSSO = index
      await LoginService.loginAcountSSO(account)
      await this.sleep(1500)
      this.multipleLoadingSSO = null
    },
  },
  async mounted() {
    // utiliser la depandance vue-cookies pour recuperer le cookie userPlaneteOnlineAccount
    let userPlaneteOnlineAccount = this.$cookies.get("userPlaneteOnlineAccount")
    if (userPlaneteOnlineAccount !== undefined && userPlaneteOnlineAccount !== null) {
      // s assurer que le cookie userPlaneteOnlineAccount est un tableau d'objets
      if (typeof userPlaneteOnlineAccount === "string") {
        userPlaneteOnlineAccount.map(account => {
          return JSON.parse(account)
        })
      }
      this.$store.commit("setAccountsSSO", userPlaneteOnlineAccount)
      // supprimer le cookie userPlaneteOnlineAccount
      this.$cookies.remove("userPlaneteOnlineAccount")
    } 
    // let authConfigurations = await SVSAuthentication.getAuthenticationConfiguration()
    // let dns = this.getDns()
    // this.authenticationConfiguration = authConfigurations.find(config => config.client == dns && config.active)
    // if (this.authenticationConfiguration.onlySso !== undefined && this.authenticationConfiguration.onlySso === true) {
      // this.$store.commit("toggleLogin", false);
      // await this.loginSSO()
    // }
    // else {
    //   this.$store.commit("toggleLogin", true);
    // }
  }
}
</script>

<style scoped>
.gradient-icon {
  background: linear-gradient(
    var(--v-tertiary-base) 10%,
    var(--v-primary-base) 50%,
    var(--v-secondary-base) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>